(function($) {
  $(function() {
    $('.js-toggler').on('click', function() {
      var $this = $(this);
      var value = $this.data('value');
      var target = $this.data('target');
      var target_parent = $this.data('target-parent');
      if (target_parent) {
        var $target = $this.closest(target_parent).find(target);
      }
      else {
        var $target = $(target);
      }
      $target[value]();
      $this.hide().siblings().show();
    });
  });
})(jQuery);
