// require blankshield/blankshield.js
// $(function() {
//   $document.on('click', 'a[href]:not([href="#"])', function (e) {
//     var this_hostname = String(this.hostname).replace(/^[a-z]{2}\./, '');
//     var location_hostname = String(location.hostname).replace(/^[a-z]{2}\./, '');

//     var isExternal = !this.href.match(/^(mailto|tel)\:/) &&               // Disable if mailto: or tel:
//                      !this.download &&                                    // Disable if download attribute
//                      (this.href.match(/\.(pdf|xls|doc|ppt)/) ||           // Enable if documents
//                      this_hostname != location_hostname ||                // Enable if different domain
//                      (this.target && this.target == '_blank') ||          // Enable if target _blank
//                      (this.rel && this.rel.match(/external|noopener/)));  // Enable if rel=external or noopener

//     if (isExternal) {
//       blankshield.open(this);
//       e.preventDefault();
//     }
//   });
// });

$(document).find('a[href]:not([href="#"])').each(function (index, link) {
  var isBlankable =
    !link.href.match(/^(mailto|tel)\:/) &&                                                     // Ignore mailto and tel
    (link.href.match(/\.(pdf|xls|doc|ppt)/) ||                                                 // Enable if document
    (link.hostname != location.hostname && (!link.download || !link.target || !link.rel)));    // Enable if different domain with no download, target or rel attributes

  if (isBlankable && !link.target) {
    link.target = "_blank";
    link.rel = "noopener";
  }
});