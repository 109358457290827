(function($) {
  $(function () {
    $('.js-play').on('click', function () {
      if ($('.js-play-on').hasClass('c-banner__play--visible')) {
        $('.js-play-on').removeClass('c-banner__play--visible');
        $('.js-play-off').addClass('c-banner__play--visible');
        // $('.js-video').prop('muted', false);
        $('.js-video').get(0).play();
      }
      else {
        $('.js-play-on').addClass('c-banner__play--visible');
        $('.js-play-off').removeClass('c-banner__play--visible');
        // $('.js-video').prop('muted', true);
        $('.js-video').get(0).pause();
      }
    });
  });
})(jQuery);
