(function($) {
  $(function() {
    var url = $('.js-filter-component').data('url');
    var $list = $('.js-filter-list');
    var $checkboxes = $('.js-checkbox-autosubmit');

    $checkboxes.on('change', function() {
      $list.css('height', $list.height());
      $('.js-loader-hide').remove();
      $('.js-loader').show();

      $.get(url, $checkboxes.serialize(), function(data) {
        var html = $(data).find('.js-filter-list').html();
        $list.html(html).css('height', 'auto');
      });
    });
  });
})(jQuery);
