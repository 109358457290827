//= require slick-carousel/slick/slick.js

$(function() {
  var conf = {
    producers: {
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1220, // jusqu'à 1220
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 980, // jusqu'à 980
          settings: {
            slidesToShow: 2,
            // centerMode: true,
            // centerPadding: '35px',
            // arrows: false,
            // infinite: true
          }
        },
        {
          breakpoint: 700, // jusqu'à 700
          settings: {
            slidesToShow: 1,
            // centerMode: true,
            // centerPadding: '35px',
            // arrows: false,
            // infinite: true
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    },
  };

  $('.c-slick').each(function() {
    // return;
    var $this = $(this);

    var component = $this.data('slick-component');
    var $component = $this.closest('.c-' + component);
    var slick_class = 'c-' + component + '--slick';
    if ($component.hasClass(slick_class)) return;
    $component.addClass(slick_class);

    $this.slick($.extend({
      dots: false,
      infinite: false,
      speed: 300,
      prevArrow: $this.prev(),
      nextArrow: $this.next(),
    }, conf[component.replace('-', '_')]));
  })
});
