(function($) {
  function createTimeout(timeoutHandler, delay) {
    var timeoutId = setTimeout(timeoutHandler, delay);
    return {
        clear: function() {
            clearTimeout(timeoutId);
        },
        trigger: function() {
            clearTimeout(timeoutId);
            return timeoutHandler();
        }
    };
  }

  $(function() {
    var $switcher = $('.js-switcher-link');
    var timeouts = [];
    $switcher.on('click mouseover', function(e) {
        e.preventDefault();
        var $this = $(this);
        var $target = $('#' + $this.data('target'));

        $this.addClass('c-pancake-shapes__itemlink--active').parent().siblings().find('a').removeClass('c-pancake-shapes__itemlink--active');

        var $siblings = $target.siblings();
        $siblings.addClass('c-pancake-shapes__item--hidden');

        for (var i = 0; i < timeouts.length; i++) {
          timeouts[i].trigger();
        }

        timeouts[0] = createTimeout(function() {
          $('.js-switcher-panel').css('height', Math.round($target.height()));
          $target.addClass('c-pancake-shapes__item--visible');
        }, 200);
        timeouts[1] = createTimeout(function() {
          $siblings.removeClass('c-pancake-shapes__item--visible').removeClass('c-pancake-shapes__item--hidden');
        }, 400);
    });

    $switcher.filter('.c-pancake-shapes__itemlink--active').trigger('click').closest('.c-pancake-shapes').addClass('c-pancake-shapes--js');
  });
})(jQuery);
